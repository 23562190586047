export const ORDER_STATUS = [
  { type: 'rovi_orders_issue_type_sent', value: 0 },
  { type: 'rovi_orders_issue_type_draft', value: 1 }
];

export const CLIENT_TYPES = [
  { type: 'rovi_orders_client_type_company', value: 0 },
  { type: 'rovi_orders_client_type_individual_person', value: 1 }
];


export const ORDERS_STATUS_SENT = 0;
export const ORDERS_STATUS_DRAFT = 1;

export const ORDER_PERIOD_ONE_DAY = 1;
export const ORDER_PERIOD_SEVEN_DAYS = 2;

export const ORDER_UNPAID_DAY = 1;
export const ORDER_PAID_PARTIALLY_DAY = 2;

export const ORDER_PRODUCT_PERIOD_ONE_DAY = 1;
export const ORDER_PRODUCT_PERIOD_SEVEN_DAYS = 7;
export const ORDER_PRODUCT_PERIOD_THIRTY_DAYS = 30;
export const ORDER_PRODUCT_PERIOD_NINETY_DAYS = 90;
export const ORDER_PRODUCT_PERIOD_ONE_YEAR = 12;
export const ORDER_PRODUCT_PERIOD_TEN_DAYS = 10;
export const ORDER_PRODUCT_PERIOD_SIXTY_DAYS = 60;

export const ORDER_CLIENT_TYPE_COMPANY = 0;
export const ORDER_CLIENT_TYPE_INDIVIDUAL_PERSON = 1;