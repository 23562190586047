import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

let init = {
  lng: localStorage.getItem('lang'),
  detection: {
    order: ['localStorage', 'lang'],
    lookupQuerystring: 'lang',
    lookupLocalStorage: 'lang',
    caches: ['localStorage']
  },
  fallbackLng: 'RO',
  interpolation: { escapeValue: false },
  backend: {
    loadPath: `${process.env.REACT_APP_GATEWAY_URL}/translations?language={{lng}}`
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(init);

export default i18n;
