import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Column } from 'spacer-ui-kit';

const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-72">
      <div className="flex flex-col space-y-5">
        <span className="w-16 h-15 bg-red-400 m-auto rounded-2xl p-3 text-3xl"><span className="path-4 text-white font-bold">X</span></span>
        <span className="font-bold text-xl">{t('rovi_forbidden_access_denied_label')}</span>
        <Row cols={3}>
          <Column colStart={2} colEnd={3} size={1}>
            <p className="text-secondary">{t('rovi_forbidden_resource_not_allowed')}</p>
            <p className="text-secondary">{t('rovi_forbidden_connect_admin')}</p>
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default Forbidden;
