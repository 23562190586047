import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SideNav as SpacerSideNav } from 'spacer-ui-kit';

import { ReactComponent as Users } from 'icons/users-white.svg';
import { ReactComponent as Orders } from 'icons/shop-white.svg';
import { ReactComponent as DocsIcon } from 'icons/docs-white.svg';
import { ReactComponent as Receipts } from 'icons/receipt-white.svg';
import { ReactComponent as Dashboard } from 'icons/dashboard-white.svg';

import { hasPermission } from 'utils/security';
import { PERMISSIONS } from 'constants';

const SideNav = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.security.user);
  const company = useSelector(state => state.security.company);

  const permissions = {
    dashboard: hasPermission(user, [PERMISSIONS.VIEW_DASHBOARD]),
    clients: hasPermission(user, [PERMISSIONS.LIST_CLIENT]),
    orders: hasPermission(user, [PERMISSIONS.LIST_ORDERS]),
    receipts: hasPermission(user, [PERMISSIONS.LIST_RECEIPTS]),
    totalCashier: hasPermission(user, [PERMISSIONS.TOTAL_CASHIER])
  };

  const history = useHistory();
  const hiddenClass = 'hidden text-opacity-100 whitespace-nowrap overflow-hidden';
  const blockClass = 'block overflow-auto whitespace-normal text-opacity-100';
  const [visibleClass, setVisibleClass] = useState(blockClass);
  const [labelClass, setLabelClass] = useState(blockClass);
  const location = window.location.href.split('/')[3];
  const [selected, setSelected] = useState(location);

  const collapseSideNav = () => {
    setVisibleClass(visibleClass ? '' : blockClass);
    setLabelClass(labelClass === blockClass ? hiddenClass : blockClass);
  };

  const handleRouteClick = route => {
    history.push(`/${route}`);
    setSelected(route);
  };

  return (
    <SpacerSideNav onCollapse={() => collapseSideNav()}>
      {permissions.dashboard
        && <div
          onClick={() => handleRouteClick('dashboard')}
          className={`bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center ${visibleClass} ${selected === 'dashboard' ? 'bg-opacity-40' : 'bg-opacity-10'}`}
        >
          <div className="text-xl mr-2 p-2 overflow-hidden">
            <Dashboard className="w-5 h-5" />
          </div>
          <div className={`${labelClass} animate-fade-in`}>{t('rovi_sidenav_dashboard_label')}</div>
        </div>
      }
      {permissions.clients
        && <div
          onClick={() => handleRouteClick('clients')}
          className={`bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center ${visibleClass} ${selected === 'clients' ? 'bg-opacity-40' : 'bg-opacity-10'}`}
        >
          <div className="text-xl mr-2 p-2 overflow-hidden">
            <Users className="w-5 h-5" />
          </div>
          <div className={`${labelClass} animate-fade-in`}>{t('rovi_sidenav_clients_label')}</div>
        </div>
      }
      {(permissions.orders || company?._id)
        && <div
          onClick={() => handleRouteClick('orders')}
          className={`${visibleClass} ${selected === 'orders' ? 'bg-opacity-40' : 'bg-opacity-10'} bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center`}
        >
          <div className="text-xl mr-2 p-2 overflow-hidden">
            <Orders className="w-5 h-5" />
          </div>
          <div className={`${labelClass} animate-fade-in`}>{t('rovi_sidenav_orders_label')}</div>
        </div>
      }
      {permissions.receipts
        && < div
          onClick={() => handleRouteClick('receipts')}
          className={`${visibleClass} ${selected === 'receipts' ? 'bg-opacity-40' : 'bg-opacity-10'} bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center`}
        >
          <div className="text-xl mr-2 p-2 overflow-hidden">
            <Receipts className="w-5 h-5" />
          </div>
          <div className={`${labelClass} animate-fade-in`}>{t('rovi_sidenav_receipts_label')}</div>
        </div>
      }
      {(permissions.orders || company?._id)
        && <div
          onClick={() => handleRouteClick('ordersReports')}
          className={`${visibleClass} ${selected === 'ordersReports' ? 'bg-opacity-40' : 'bg-opacity-10'} bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center`}
        >
          <div className="text-xl mr-2 p-2 overflow-hidden">
            <DocsIcon className="w-5 h-5" />
          </div>
          <div className={`${labelClass} animate-fade-in`}>{t('rovi_sidenav_orders_reports_label')}</div>
        </div>
      }
      {(permissions.receipts && permissions.orders)
        && <div
          onClick={() => handleRouteClick('receiptsReports')}
          className={`${visibleClass} ${selected === 'receiptsReports' ? 'bg-opacity-40' : 'bg-opacity-10'} bg-dark hover:bg-opacity-20 rounded-xl mx-3.5 text-white flex flex-row mb-2.5 cursor-pointer items-center`}
        >
          <div className="text-xl mr-2 p-2 overflow-hidden">
            <DocsIcon className="w-5 h-5" />
          </div>
          <div className={`${labelClass} animate-fade-in`}>{t('rovi_sidenav_receipts_reports_label')}</div>
        </div>
      }
    </SpacerSideNav >
  );
};

export default SideNav;
