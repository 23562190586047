import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { PERMISSIONS } from 'constants';

const Orders = lazy(() => import('pages/orders'));
const Clients = lazy(() => import('pages/clients'));
const Receipts = lazy(() => import('pages/receipts'));
const Dashboard = lazy(() => import('pages/dashboard'));
const Forbidden = lazy(() => import('pages/errors/forbidden'));
const OrdersReports = lazy(() => import('pages/orders/reports'));
const ReceiptsReports = lazy(() => import('pages/orders/receiptsReports'));

export const routes = [
  {
    path: '/forbidden',
    exact: true,
    component: Forbidden,
    isPublic: true
  },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/orders" />,
    permissions: [PERMISSIONS.LIST_ORDERS]
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    permissions: [PERMISSIONS.VIEW_DASHBOARD],
    backgroundColor: 'bg-dark-shade'
  },
  {
    path: '/clients',
    exact: true,
    component: Clients,
    permissions: [PERMISSIONS.LIST_CLIENT]
  },
  {
    path: '/orders',
    exact: true,
    component: Orders,
    permissions: [PERMISSIONS.LIST_ORDERS]
  },
  {
    path: '/receipts',
    exact: true,
    component: Receipts,
    permissions: [PERMISSIONS.LIST_RECEIPTS]
  },
  {
    path: '/ordersReports',
    exact: true,
    component: OrdersReports,
    permissions: [PERMISSIONS.LIST_ORDERS]
  },
  {
    path: '/receiptsReports',
    exact: true,
    component: ReceiptsReports,
    permissions: [PERMISSIONS.LIST_ORDERS && PERMISSIONS.LIST_RECEIPTS]
  }
];
