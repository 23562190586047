import React from 'react';
import i18n from 'utils/translations';

import { hasPermission } from 'utils/security';

import { PERMISSIONS } from 'constants';

import { ReactComponent as StackIcon } from 'icons/stack.svg';
import { ReactComponent as UsersIcon } from 'icons/users.svg';
import { ReactComponent as WorldIcon } from 'icons/world.svg';

export const languages = [{
  label: 'EN',
  switch: () => i18n.changeLanguage('EN')
}, {
  label: 'RO',
  switch: () => i18n.changeLanguage('RO')
}];

const redirect = url => {
  window.location.href = url;
};

export const getApps = user => (
  [{
    name: 'Logs',
    icon: <StackIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_LOG_LIST]),
    onClick: () => redirect(`${process.env.REACT_APP_ANALYTICS}/logs`)
  }, {
    name: 'Users',
    icon: <UsersIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_USER_LIST, PERMISSIONS.VIEW_USER_LIST]),
    onClick: () => redirect(`${process.env.REACT_APP_TEAMS}/users`)
  }, {
    name: 'Translations',
    icon: <WorldIcon className="w-5 h-5" />,
    hasPermission: hasPermission(user, [PERMISSIONS.VIEW_TRANSLATION_LIST]),
    onClick: () => redirect(`${process.env.REACT_APP_TRANSLATIONS}/translations`)
  }]
);
