import React from 'react';
import { Route as ReactRoute, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { hasPermission } from 'utils/security';

const Route = ({ component: Component, ...rest }) => {
  const security = useSelector(state => state.security);
  const { user, company } = security;

  const handlePermission = props => {
    const { isPublic, permissions } = rest;

    if (isPublic) {
      return <Component {...props} />;
    }

    if (company?._id && (props.location.pathname === '/orders' || props.location.pathname === '/ordersReports')) {
      return <Component {...props} />;
    }

    return hasPermission(user, permissions)
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/forbidden' }} />;
  };

  return <ReactRoute {...rest} render={props => handlePermission(props)} />;
};

export default Route;
