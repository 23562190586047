export const COMMAND_ISSUE_TYPES = [
  { type: 'rovi_command_issue_type_advance', value: 0 },
  { type: 'rovi_command_issue_type_term', value: 1 }
];

export const CLIENTS_ISSUE_TYPE_TERM = 1;

export const DAYS_OF_WEEK = [
  { value: 'rovi_client_sunday_label', key: 0 },
  { value: 'rovi_client_monday_label', key: 1 },
  { value: 'rovi_client_tuesday_label', key: 2 },
  { value: 'rovi_client_wednesday_label', key: 3 },
  { value: 'rovi_client_thursday_label', key: 4 },
  { value: 'rovi_client_friday_label', key: 5 },
  { value: 'rovi_client_saturday_label', key: 6 }
];

export const LIMIT_TYPE = [
  { value: 'rovi_client_day_of_week_type', key: 0 },
  { value: 'rovi_client_due_days_type', key: 1 }
  // { value: 'rovi_command_issue_type_date_of_month', key: 2 }
];

export const CLIENT_TYPE_TERM = 1;