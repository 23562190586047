import React, { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';

import useSWR from 'swr';

import { TopBar } from 'spacer-ui-components';
import { LoadingSpinner } from 'spacer-ui-kit';

import { routes } from 'routes';
import { setUser, setCompany } from 'store/security';

import axios from 'utils/axios';

import SideNav from './components/side-nav';
import Route from 'components/security/route';

import { getStorage } from 'utils/storage';
import { getApps, languages } from 'utils/navigation';

import Forbidden from 'pages/errors/forbidden';

import { hasPermission } from 'utils/security';
import { PERMISSIONS } from 'constants';

import { ReactComponent as RoviLogo } from 'icons/logo.svg';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: me, error } = useSWR(`${process.env.REACT_APP_GATEWAY_URL}/auth/me`, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 60000 // How often the authentication should be check (to be determined)
  });

  const { data: company, error: companyError } = useSWR(me && !hasPermission(me, [PERMISSIONS.TOTAL_CASHIER]) ? `${process.env.REACT_APP_ROVINIETE_SERVICE}/users/${me.id}/clients` : null);

  if (!me && !error) {
    return <LoadingSpinner header="Please wait" />;
  }

  if (me && !hasPermission(me, [PERMISSIONS.TOTAL_CASHIER]) && !company && !companyError) {
    return <LoadingSpinner header="Please wait" />;
  }

  if (me) {
    dispatch(setUser(me));
  }

  if (company && !hasPermission(me, [PERMISSIONS.TOTAL_CASHIER])) {
    dispatch(setCompany(company));
  }

  const logout = async () => {
    await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/logout`);

    dispatch(setUser(null));
    dispatch(setCompany(null));

    window.location.href = process.env.REACT_APP_SSO_LOGIN_URL;
  };

  const getBackgroundColor = () => {
    let background = 'bg-light';
    routes.map((props, index) => {
      if (props.path === location.pathname && props.backgroundColor) {
        background = props.backgroundColor;
      }

      return background;
    });

    return background;
  };

  return (
    <>
      <TopBar
        user={me}
        selectedLanguage={getStorage('lang')}
        onLogout={() => logout()}
        hasNotification={false}
        languages={languages}
        appsList={getApps(me)}
        icon={<RoviLogo className="w-14 h-14" />}
      />
      <div className={`min-h-screen ${getBackgroundColor()} flex flex-row`}>
        <Suspense fallback={<LoadingSpinner />}>
          <SideNav />
        </Suspense>
        <div className='w-11/12 ml-12'>
          {(!company && companyError?.status === 404 && !hasPermission(me, [PERMISSIONS.TOTAL_CASHIER]))
            ? <Forbidden />
            : <Suspense fallback={<LoadingSpinner />}>
              <Switch>
                {routes.map((props, index) => <Route key={index} {...props} />)}
              </Switch>
            </Suspense>
          }
        </div>
      </div>
    </>
  );
};

export default App;
