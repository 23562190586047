export const PERMISSIONS = {
  TOTAL_CASHIER: 'total_cashier',

  VIEW_ROLE_LIST: 'view_role_list',
  VIEW_LOG_LIST: 'view_log_list',
  VIEW_USER_LIST: 'view_user_list',
  VIEW_TRANSLATION_LIST: 'view_translations_list',

  VIEW_DASHBOARD: 'view_dashboard',

  ADD_CLIENT: 'add_client',
  EDIT_CLIENT: 'edit_client',
  DELETE_CLIENT: 'delete_client',
  VIEW_CLIENT_DETAILS: 'view_client_details',
  LIST_CLIENT: 'list_client',

  ADD_ORDER: 'add_order',
  EDIT_ORDER: 'edit_order',
  DELETE_ORDER: 'delete_order',
  VIEW_ORDER_DETAILS: 'view_order_details',
  LIST_ORDERS: 'list_orders',

  ADD_RECEIPT: 'add_receipt',
  EDIT_RECEIPT: 'edit_receipt',
  DELETE_RECEIPT: 'delete_receipt',
  VIEW_RECEIPTS_DETAILS: 'view_receipt_details',
  LIST_RECEIPTS: 'list_receipts',

  IGNORE_ORDER_DUE_DATE: 'ignore_order_due_date',
  IGNORE_ORDER_LIMIT_SOLD: 'ignore_order_limit_sold',
  IGNORE_ORDER_ADVANCE: 'ignore_order_advance'
};

export const FULL_PERMISSIONS = Object.values(PERMISSIONS);
