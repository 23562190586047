export const COUNTRIES = [
  {
    name: 'ROMANIA',
    value: 'RO'
  },
  {
    name: 'UNGARIA',
    value: 'HU'
  },
  {
    name: 'BULGARIA',
    value: 'BG'
  },
  {
    name: 'AFGANISTAN',
    value: 'AF'
  },
  {
    name: 'AFRICA DE SUD',
    value: 'ZA'
  },
  {
    name: 'ALBANIA',
    value: 'AL'
  },
  {
    name: 'ALGERIA',
    value: 'DZ'
  },
  {
    name: 'ANDORRA',
    value: 'AD'
  },
  {
    name: 'ANGOLA',
    value: 'AO'
  },
  {
    name: 'ANGUILLA',
    value: 'AI'
  },
  {
    name: 'ANTARCTICA',
    value: 'AQ'
  },
  {
    name: 'ANTIGUA SI BARBUDA',
    value: 'AG'
  },
  {
    name: 'ANTILELE OLANDEZE',
    value: 'AN'
  },
  {
    name: 'ARABIA SAUDITA',
    value: 'SA'
  },
  {
    name: 'ARGENTINA',
    value: 'AR'
  },
  {
    name: 'ARMENIA',
    value: 'AM'
  },
  {
    name: 'ARUBA',
    value: 'AW'
  },
  {
    name: 'AUSTRALIA',
    value: 'AU'
  },
  {
    name: 'AUSTRIA',
    value: 'AT'
  },
  {
    name: 'AZERBAIDJAN',
    value: 'AZ'
  },
  {
    name: 'BAHAMAS',
    value: 'BS'
  },
  {
    name: 'BAHREIN',
    value: 'BH'
  },
  {
    name: 'BANGLADESH',
    value: 'BD'
  },
  {
    name: 'BARBADOS',
    value: 'BB'
  },
  {
    name: 'BELARUS',
    value: 'BY'
  },
  {
    name: 'BELGIA',
    value: 'BE'
  },
  {
    name: 'BELIZE',
    value: 'BZ'
  },
  {
    name: 'BENIN',
    value: 'BJ'
  },
  {
    name: 'BERMUDE (INSULELE)',
    value: 'BM'
  },
  {
    name: 'BHUTAN',
    value: 'BT'
  },
  {
    name: 'BOLIVIA',
    value: 'BO'
  },
  {
    name: 'BOSNIA SI HERTEGOVINA',
    value: 'BA'
  },
  {
    name: 'BOTSWANA',
    value: 'BW'
  },
  {
    name: 'BOUVET (INSULA)',
    value: 'BV'
  },
  {
    name: 'BRAZILIA',
    value: 'BR'
  },
  {
    name: 'BRUNEI',
    value: 'BN'
  },
  {
    name: 'BURUNDI',
    value: 'BI'
  },
  {
    name: 'CAMBODGIA',
    value: 'KH'
  },
  {
    name: 'CAMERUN',
    value: 'CM'
  },
  {
    name: 'CANADA',
    value: 'CA'
  },
  {
    name: 'CAPUL VERDE',
    value: 'CV'
  },
  {
    name: 'CAYMAN (INSULELE)',
    value: 'KY'
  },
  {
    name: 'CEHIA',
    value: 'CZ'
  },
  {
    name: 'CENTRAFRICANÃ (REPUBLICA)',
    value: 'CF'
  },
  {
    name: 'CHILE',
    value: 'CL'
  },
  {
    name: 'CHINA',
    value: 'CN'
  },
  {
    name: 'CHRISTMAS (INSULA)',
    value: 'CX'
  },
  {
    name: 'CIAD',
    value: 'TD'
  },
  {
    name: 'CIPRU',
    value: 'CY'
  },
  {
    name: 'COASTA DE FILDES',
    value: 'CI'
  },
  {
    name: 'COCOS (KEELING',
    value: 'CC'
  },
  {
    name: 'COLUMBIA',
    value: 'CO'
  },
  {
    name: 'COMORE',
    value: 'KM'
  },
  {
    name: 'CONGO',
    value: 'CG'
  },
  {
    name: 'CONGO (REPUBLICA DEMOCRATÃ)',
    value: 'CD'
  },
  {
    name: 'COOK',
    value: 'CK'
  },
  {
    name: 'COREEA DE SUD',
    value: 'KR'
  },
  {
    name: 'COSTA RICA',
    value: 'CR'
  },
  {
    name: 'CROATIA',
    value: 'HR'
  },
  {
    name: 'CUBA',
    value: 'CU'
  },
  {
    name: 'CURACAO',
    value: 'CW'
  },
  {
    name: 'DANEMARCA',
    value: 'DK'
  },
  {
    name: 'DJIBOUTI',
    value: 'DJ'
  },
  {
    name: 'DOMINICA',
    value: 'DM'
  },
  {
    name: 'ECUADOR',
    value: 'EC'
  },
  {
    name: 'EGIPT',
    value: 'EG'
  },
  {
    name: 'EL SALVADOR',
    value: 'SV'
  },
  {
    name: 'ELVETIA',
    value: 'CH'
  },
  {
    name: 'EMIRATELE  ARABE UNITE',
    value: 'AE'
  },
  {
    name: 'ERITREEA',
    value: 'ER'
  },
  {
    name: 'ESTONIA',
    value: 'EE'
  },
  {
    name: 'ETIOPIA',
    value: 'ET'
  },
  {
    name: 'FALKLAND (MALVINE',
    value: 'FK'
  },
  {
    name: 'FAROE (INSULELE)',
    value: 'FO'
  },
  {
    name: 'FIJI',
    value: 'FJ'
  },
  {
    name: 'FILIPINE',
    value: 'PH'
  },
  {
    name: 'FINLANDA',
    value: 'FI'
  },
  {
    name: 'FRANTA',
    value: 'FR'
  },
  {
    name: 'FÂSIA GAZA SI TERITORIILE DE VEST',
    value: 'PS'
  },
  {
    name: 'GABON',
    value: 'GA'
  },
  {
    name: 'GAMBIA',
    value: 'GM'
  },
  {
    name: 'GEORGIA',
    value: 'GE'
  },
  {
    name: 'GERMANIA',
    value: 'DE'
  },
  {
    name: 'GHANA',
    value: 'GH'
  },
  {
    name: 'GIBRALTAR',
    value: 'GI'
  },
  {
    name: 'GRECIA',
    value: 'EL'
  },
  {
    name: 'GRENADA',
    value: 'GD'
  },
  {
    name: 'GROENLANDA',
    value: 'GL'
  },
  {
    name: 'GUADELUPA',
    value: 'GP'
  },
  {
    name: 'GUAM',
    value: 'GU'
  },
  {
    name: 'GUATEMALA',
    value: 'GT'
  },
  {
    name: 'GUERNSEY',
    value: 'GG'
  },
  {
    name: 'GUIANA FRANCEZÃ',
    value: 'GF'
  },
  {
    name: 'GUINEEA',
    value: 'GN'
  },
  {
    name: 'GUINEEA ECUATORIALÃ',
    value: 'GQ'
  },
  {
    name: 'GUINEEA-BISSAU',
    value: 'GW'
  },
  {
    name: 'GUYANA',
    value: 'GY'
  },
  {
    name: 'HAITI',
    value: 'HT'
  },
  {
    name: 'HEARD SI MCDONALD (INSULELE)',
    value: 'HM'
  },
  {
    name: 'HONDURAS',
    value: 'HN'
  },
  {
    name: 'HONG KONG',
    value: 'HK'
  },
  {
    name: 'INDIA',
    value: 'IN'
  },
  {
    name: 'INDONEZIA',
    value: 'ID'
  },
  {
    name: 'INSULA MAN',
    value: 'IM'
  },
  {
    name: 'INSULELE ALAND (PROVINCIE A FINLANDEI)',
    value: 'XZ'
  },
  {
    name: 'IORDANIA',
    value: 'JO'
  },
  {
    name: 'IRAK',
    value: 'IQ'
  },
  {
    name: 'IRAN',
    value: 'IR'
  },
  {
    name: 'IRLANDA',
    value: 'IE'
  },
  {
    name: 'ISLANDA',
    value: 'IS'
  },
  {
    name: 'ISRAEL',
    value: 'IL'
  },
  {
    name: 'ITALIA',
    value: 'IT'
  },
  {
    name: 'JAMAICA',
    value: 'JM'
  },
  {
    name: 'JAPONIA',
    value: 'JP'
  },
  {
    name: 'JERSEY',
    value: 'JE'
  },
  {
    name: 'KAZAHSTAN',
    value: 'KZ'
  },
  {
    name: 'KENYA',
    value: 'KE'
  },
  {
    name: 'KIRGHIZTAN',
    value: 'KI'
  },
  {
    name: 'KOSOVO',
    value: 'XK'
  },
  {
    name: 'KUWEIT',
    value: 'KW'
  },
  {
    name: 'LAOS',
    value: 'LA'
  },
  {
    name: 'LESOTHO',
    value: 'LS'
  },
  {
    name: 'LETONIA',
    value: 'LV'
  },
  {
    name: 'LIBAN',
    value: 'LB'
  },
  {
    name: 'LIBERIA',
    value: 'LR'
  },
  {
    name: 'LIBIA',
    value: 'LY'
  },
  {
    name: 'LIECHTENSTEIN',
    value: 'LI'
  },
  {
    name: 'LITUANIA',
    value: 'LT'
  },
  {
    name: 'LUXEMBURG',
    value: 'LU'
  },
  {
    name: 'MACAO',
    value: 'MO'
  },
  {
    name: 'MACEDONIA',
    value: 'MK'
  },
  {
    name: 'MADAGASCAR',
    value: 'MG'
  },
  {
    name: 'MALAEZIA',
    value: 'MY'
  },
  {
    name: 'MALAWI',
    value: 'MW'
  },
  {
    name: 'MALDIVE (INSULELE)',
    value: 'MV'
  },
  {
    name: 'MALI',
    value: 'ML'
  },
  {
    name: 'MALTA',
    value: 'MT'
  },
  {
    name: 'MAREA BRITANIE',
    value: 'GB'
  },
  {
    name: 'MARIANE DE NORD (INSULELE)',
    value: 'MP'
  },
  {
    name: 'MAROC',
    value: 'MA'
  },
  {
    name: 'MARSHALL (INSULELE)',
    value: 'MH'
  },
  {
    name: 'MARTINICA',
    value: 'MQ'
  },
  {
    name: 'MAURITANIA',
    value: 'MR'
  },
  {
    name: 'MAURITIUS',
    value: 'MU'
  },
  {
    name: 'MAYOTTE',
    value: 'YT'
  },
  {
    name: 'MEXIC',
    value: 'MX'
  },
  {
    name: 'MICRONEZIA',
    value: 'FM'
  },
  {
    name: 'MOLDOVA',
    value: 'MD'
  },
  {
    name: 'MONACO',
    value: 'MC'
  },
  {
    name: 'MONGOLIA',
    value: 'MN'
  },
  {
    name: 'MONTSERRAT',
    value: 'MS'
  },
  {
    name: 'MOZAMBIC',
    value: 'MZ'
  },
  {
    name: 'MUNTENEGRU',
    value: 'ME'
  },
  {
    name: 'MYANMAR',
    value: 'MM'
  },
  {
    name: 'NAMIBIA',
    value: 'NA'
  },
  {
    name: 'NAURU',
    value: 'NR'
  },
  {
    name: 'NEPAL',
    value: 'NP'
  },
  {
    name: 'NICARAGUA',
    value: 'NI'
  },
  {
    name: 'NIGER',
    value: 'NE'
  },
  {
    name: 'NIGERIA',
    value: 'NG'
  },
  {
    name: 'NIUE',
    value: 'NU'
  },
  {
    name: 'NORFOLK (INSULELE)',
    value: 'NF'
  },
  {
    name: 'NORVEGIA',
    value: 'NO'
  },
  {
    name: 'NOUA CALEDONIE',
    value: 'NC'
  },
  {
    name: 'NOUA ZEELANDA',
    value: 'NZ'
  },
  {
    name: 'OCEANUL INDIAN (TERITORIILE BRITANICE)',
    value: 'VG'
  },
  {
    name: 'OLANDA',
    value: 'NL'
  },
  {
    name: 'OMAN',
    value: 'OM'
  },
  {
    name: 'PAKISTAN',
    value: 'PK'
  },
  {
    name: 'PALAU',
    value: 'PW'
  },
  {
    name: 'PANAMA',
    value: 'PA'
  },
  {
    name: 'PAPUA NOUA GUINEE',
    value: 'PG'
  },
  {
    name: 'PARAGUAY',
    value: 'PY'
  },
  {
    name: 'PERU',
    value: 'PE'
  },
  {
    name: 'PITCAIRN',
    value: 'PN'
  },
  {
    name: 'POLONIA',
    value: 'PL'
  },
  {
    name: 'PORTUGALIA',
    value: 'PT'
  },
  {
    name: 'PUERTO RICO',
    value: 'PR'
  },
  {
    name: 'QATAR',
    value: 'QA'
  },
  {
    name: 'R. P. D. COREEA',
    value: 'KP'
  },
  {
    name: 'REP DOMINICANA',
    value: 'DO'
  },
  {
    name: 'REUNION',
    value: 'RE'
  },
  {
    name: 'RUSIA',
    value: 'RU'
  },
  {
    name: 'RWANDA',
    value: 'RW'
  },
  {
    name: 'SAINT KITTS SI NEVIS',
    value: 'KN'
  },
  {
    name: 'SAINT PIERRE SI MIQUELON',
    value: 'PM'
  },
  {
    name: 'SAMOA',
    value: 'WS'
  },
  {
    name: 'SAN MARINO',
    value: 'SM'
  },
  {
    name: 'SAO TOME SI PRINCIPE',
    value: 'ST'
  },
  {
    name: 'SENEGAL',
    value: 'SN'
  },
  {
    name: 'SEYCHELLES',
    value: 'SC'
  },
  {
    name: 'SF. ELENA (INSULELE)',
    value: 'SH'
  },
  {
    name: 'SF. LUCIA',
    value: 'LC'
  },
  {
    name: 'SF. VICENÞIU SI GRENADINES',
    value: 'VC'
  },
  {
    name: 'SIERRA LEONE',
    value: 'SL'
  },
  {
    name: 'SINGAPORE',
    value: 'SG'
  },
  {
    name: 'SIRIA',
    value: 'SY'
  },
  {
    name: 'SLOVACIA',
    value: 'SK'
  },
  {
    name: 'SLOVENIA',
    value: 'SI'
  },
  {
    name: 'SOLOMON (INSULELE)',
    value: 'SB'
  },
  {
    name: 'SOMALIA',
    value: 'SO'
  },
  {
    name: 'SPANIA',
    value: 'ES'
  },
  {
    name: 'SRI LANKA',
    value: 'LK'
  },
  {
    name: 'STATELE UNITE ALE AMERICII',
    value: 'US'
  },
  {
    name: 'SUDAN',
    value: 'SD'
  },
  {
    name: 'SUEDIA',
    value: 'SE'
  },
  {
    name: 'SVALBARD SI JAN MAYEN',
    value: 'SJ'
  },
  {
    name: 'SWAZILAND',
    value: 'SZ'
  },
  {
    name: 'TADJIKISTAN',
    value: 'TJ'
  },
  {
    name: 'TAIWAN',
    value: 'TW'
  },
  {
    name: 'TANZANIA',
    value: 'TZ'
  },
  {
    name: 'TERITORIILE AUSTRALE FRANCEZE',
    value: 'TF'
  },
  {
    name: 'TERITORIILE ÎNDEPÃRTATE ALE SUA',
    value: 'UM'
  },
  {
    name: 'THAILANDA',
    value: 'TH'
  },
  {
    name: 'TIMOR-LESTE',
    value: 'TL'
  },
  {
    name: 'TOGO',
    value: 'TG'
  },
  {
    name: 'TOKELAU',
    value: 'TK'
  },
  {
    name: 'TONGA',
    value: 'TO'
  },
  {
    name: 'TRINIDAD TOBAGO',
    value: 'TT'
  },
  {
    name: 'TUNISIA',
    value: 'TN'
  },
  {
    name: 'TURCIA',
    value: 'TR'
  },
  {
    name: 'TURKMENISTAN',
    value: 'TM'
  },
  {
    name: 'TURKS SI CAICOS (INSULELE)',
    value: 'TC'
  },
  {
    name: 'TUVALU',
    value: 'TV'
  },
  {
    name: 'UCRAINA',
    value: 'UA'
  },
  {
    name: 'UGANDA',
    value: 'UG'
  },
  {
    name: 'URUGUAY',
    value: 'UY'
  },
  {
    name: 'UZBEKISTAN',
    value: 'UZ'
  },
  {
    name: 'VENEZUELA',
    value: 'VE'
  },
  {
    name: 'VIETNAM',
    value: 'VN'
  },
  {
    name: 'VIRGINE AMERICANE (INSULELE)',
    value: 'VA'
  },
  {
    name: 'VIRGINE BRITANICE (INSULELE)',
    value: 'VB'
  },
  {
    name: 'ZAMBIA',
    value: 'ZM'
  },
  {
    name: 'ZIMBABWE',
    value: 'ZW'
  }
];
